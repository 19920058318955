var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('span',{staticClass:"text-h5"},[_vm._v(" Relatorio Total de Assinaturas Por Plano ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('date-field',{attrs:{"label":"Mês de Apuração"},model:{value:(_vm.planList.filters.period),callback:function ($$v) {_vm.$set(_vm.planList.filters, "period", $$v)},expression:"planList.filters.period"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"type":"button","color":"primary","outlined":""},on:{"click":_vm.list}},[_vm._v("Pesquisar")])],1)],1),_vm._l((_vm.planList.data),function(providerName){return _c('div',{key:providerName},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(providerName[0].provider_name)+" ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.planList.headers,"items":providerName,"items-per-page":25,"disable-sort":true,"loading":_vm.planList.loading,"no-data-text":"","loading-text":"Carregando dados...","hide-default-footer":"","item-value":"plan_id"},scopedSlots:_vm._u([{key:"item.plan_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"list-item-title"},[_vm._v(" "+_vm._s(item.plan_name)+" ")])]}},{key:"item.inativos",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"list-item-title"},[_vm._v(" "+_vm._s(item.inativos)+" ")])]}},{key:"item.ativos",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"list-item-title"},[_vm._v(" "+_vm._s(item.ativos)+" ")])]}},{key:"item.assinantes_no_mes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"list-item-title"},[_vm._v(" "+_vm._s(item.assinantes_no_mes)+" ")])]}},{key:"item.inativos_no_mes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"list-item-title"},[_vm._v(" "+_vm._s(item.inativos_no_mes)+" ")])]}}],null,true)})],1)],1)],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }