import httpClient from './http-client';

const TotalSubscriptions = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('reports', 'totalSubscriptions', params);
};

export default {
    TotalSubscriptions,
}