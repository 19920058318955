<template>
  <div>
    <v-text-field 
      :label="label"
      v-mask="'##/####'"
      v-model="inputDate" 
      @click="menu = true"
      v-on:keydown="$event.preventDefault()"
      >

      <template v-slot:append>
        <v-menu v-model="menu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-calendar</v-icon>
          </template>
          <v-date-picker :value="calendarDate" @input="selectedDate($event)" @click="menu = false" view-mode="year"
            type="month" />
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["label", "value"],
  data() {
    return {
      menu: false,
      displayDate: null,
      inputDate: null,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.inputDate = moment(val).format("MM/YYYY");
      }
    },
    inputDate(val) {
      if (val && val.length === 8) {
        this.selectedDate(moment(val, "MM/YYYY").toDate());
      }
    },
  },
  computed: {
    calendarDate() {
      if (this.inputDate && this.inputDate.length === 8) {
        let date = moment(this.inputDate, "MM/YYYY");

        if (date.isValid) {
          return date.format("YYYY-MM");
        }
      }

      return null;
    },
  },
  methods: {
    selectedDate(date) {
      if (date) {
        this.$emit(`input`, date);
        this.menu = false;
      }
    },
  },
};
</script>