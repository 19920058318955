<template>
  <v-form @submit.prevent="list(true)">
    <v-container>
      <v-row>
        <v-col :cols="12">
          <span class="text-h5">
            Provedores
            <v-btn
              fab
              color="primary accent-2"
              x-small
              @click="addItem"
              v-auth="'PROVIDER.ADD'"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </span>
        </v-col>
      </v-row>
      <v-form @submit.prevent="list(true)">
        <v-row>
          <v-col :cols="12">
            <v-card>
              <v-card-title class="card-title">
                <v-icon class="mr-1">mdi-magnify</v-icon>
                Filtro</v-card-title
              >
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Nome"
                      v-model="providerList.filter.name"
                      v-debounce:300ms="list"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="data.providerTypes"
                      label="Tipo"
                      v-model="providerList.filter.type"
                      item-value="id"
                      item-text="name"
                      @change="list"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="data.providerStatus"
                      label="Status"
                      v-model="providerList.filter.status"
                      item-value="id"
                      item-text="name"
                      @change="list"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col :cols="12">
          <v-card>
            <v-card-text>
              <Paginator
                v-bind.sync="providerList.pagingOptions"
                @page="pageSelected"
              ></Paginator>

              <v-data-table
                :headers="providerList.headers"
                :items="providerList.data"
                :items-per-page="25"
                :disable-sort="true"
                :loading="providerList.loading"
                no-data-text=""
                loading-text="Carregando dados..."
                class="app-table hover-table"
                @click:row="viewItem"
                hide-default-footer
              >
                <template v-slot:[`item.name`]="{ item }">
                  <span class="list-item-title">{{ item.name }}</span
                  ><br />
                  <span class="list-item-subtitle">{{ item.cpf_cnpj }}</span>
                </template>
                <template v-slot:[`item.location`]="{ item }">
                  {{ item.city }} - {{ item.state }}
                </template>
                <template v-slot:[`item.contact`]="{ item }">
                  <span class="list-item-title">{{ item.contact_name }}</span
                  ><br />
                  <span class="list-item-subtitle">{{
                    item.contact_phone
                  }}</span>
                </template>
                <template v-slot:[`item.api_url`]="{ item }">
                  <a
                    :href="item.api_url"
                    target="_blank"
                    @click.stop
                    v-if="item.api_url"
                    >[link]</a
                  >
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    small
                    :color="
                      item.status | enum(data.providerStatus, 'id', 'class')
                    "
                    dark
                    >{{ item.status | enum(data.providerStatus) }}</v-chip
                  >
                  &nbsp;
                  <v-chip
                    small
                    outlined
                    label
                    :color="getApiColor(item)"
                    dark
                    >v{{ item.api_version  }}</v-chip
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import providerApi from "@/common/api/provider.api";
import providerTypes from "@/common/data/provider-types.data";
import providerStatus from "@/common/data/provider-status.data";
import Paginator from "@/components/layout/Paginator";

export default {
  name: "ProviderList",

  components: { Paginator },

  data: () => ({
    data: {
      providerTypes: [{ id: "", name: "Todos:" }, ...providerTypes],
      providerStatus: [{ id: "", name: "Todos:" }, ...providerStatus],
    },
    providerList: {
      filter: {
        type: "",
        status: "",
      },
      headers: [
        { text: "Razão social", value: "name" },
        { text: "Localização", value: "location" },
        { text: "Contato", value: "contact" },
        { text: "Acesso", value: "api_url" },
        { text: "Status", value: "status" },
      ],
      data: [],
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
    },
  }),

  created() {
    this.list();
  },

  

  methods: {
    pageSelected(page) {
      this.providerList.pagingOptions.currentPage = page;
      this.list();
    },

    addItem() {
      this.$router.push(`provedores/novo`);
    },

    list() {
      this.providerList.filter.page =
        this.providerList.pagingOptions.currentPage;

      this.providerList.loading = true;
      providerApi.list(this.providerList.filter).then((result) => {
        this.providerList.loading = false;
        this.providerList.data = result.data.data;
        this.providerList.pagingOptions = result.data.pagingOptions;
      });
    },

    viewItem(item) {
      this.$router.push(`provedores/${item.id}`);
    },

    getApiColor(item) {
      return (item.api_version === 1 ? 'orange' : 'purple')
    },
  },
};
</script>