const data = [
    {
        id: 'A',
        name: 'Ativo',
        class: 'green'
    },
    {
        id: 'I',
        name: 'Inativo',
        class: 'red'
    },
    {
        id: 'T',
        name: 'Aguardando Ativação',
        class: 'orange'
    },
    {
        id: 'C',
        name: 'Cancelado',
        class: 'grey'
    }
];

export default [...data];