import httpClient from './http-client';

const getSelectedClient = () => {
    const data = localStorage.getItem('SELECTED_CLIENT');
    let value = {};

    if (data) {
        try {
            value = JSON.parse(data);
        }
        catch {
            value = {};
        }
    }

    return value;
};

const setSelectedClient = (value) => {
    localStorage.setItem('SELECTED_CLIENT', JSON.stringify(value));
};

const list = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('client', 'list', params);
};

export default {
    getSelectedClient,
    setSelectedClient,
    list
}