<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">
          Relatorio Total de Assinaturas Por Plano
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="2">
        <date-field label="Mês de Apuração" v-model="planList.filters.period" />
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn type="button" color="primary" outlined @click="list">Pesquisar</v-btn>
      </v-col>
    </v-row>

    <div v-bind:key="providerName" v-for="providerName in planList.data">
      <v-row>
        <v-col :cols="12">
          <v-card>
            <v-card-title>
              {{ providerName[0].provider_name }}
            </v-card-title>

            <v-card-text>
              <v-data-table :headers="planList.headers" :items="providerName" :items-per-page="25" :disable-sort="true"
                :loading="planList.loading" no-data-text="" loading-text="Carregando dados..."
                class="app-table hover-table" hide-default-footer item-value="plan_id">

                <template v-slot:[`item.plan_name`]="{ item }">
                  <span class="list-item-title">
                    {{ item.plan_name }}
                  </span>
                </template>
                <template v-slot:[`item.inativos`]="{ item }">
                  <span class="list-item-title">
                    {{ item.inativos }}
                  </span>
                </template>
                <template v-slot:[`item.ativos`]="{ item }">  
                  <span class="list-item-title">
                    {{ item.ativos }}
                  </span>
                </template>
                <template v-slot:[`item.assinantes_no_mes`]="{ item }">
                  <span class="list-item-title">
                    {{ item.assinantes_no_mes }}
                  </span>
                </template>
                <template v-slot:[`item.inativos_no_mes`]="{ item }">
                  <span class="list-item-title">
                    {{ item.inativos_no_mes }}
                  </span>
                </template>
                

              </v-data-table>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { eventHub } from "@/main";
import reportsApi from "@/common/api/reports.api";
import clientApi from "@/common/api/client.api";
import subscriptionPlanStatus from "@/common/data/subscription-plan-status.data";
import DateField from "@/components/DateMonthField.vue";

export default {
  name: "TotalSubscriptionList",

  components: { DateField },

  data: () => ({
    data: {
      subscriptionPlanStatus: subscriptionPlanStatus,
    },
    context: { provider: {} },
    planList: {
      headers: [
        { text: "Nome", value: "plan_name", key: "plan_id" },
        { text: "Inativos", value: "inativos", align: "center" }, 
        { text: "Ativos", value: "ativos", align: "center" },
        { text: "Aguardando Ativação", value: "agurandado_ativacao", align: "center" },
        { text: "Ativados no Mes", value: "assinantes_no_mes", align: "center" },
        { text: "Inativados no Mes", value: "inativos_no_mes", align: "center" },
      ],
      data: {},
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
      filters: {
        period: null
      }
    },
  }),

  beforeDestroy() {
    eventHub.$off("SELECTED_CLIENT");
  },

  mounted() {
    eventHub.$on("SELECTED_CLIENT", () => this.list());
  },

  created() {
    this.list();
  },

  methods: {
    list() {
      if (this.planList.filters.period === null ){
        this.planList.filters.period = new Date().toISOString().substring(0, 7);
      }

      let filter = {
        providerId: clientApi.getSelectedClient().id,
        period: this.planList.filters.period,
      };

      this.planList.loading = true;

     reportsApi.TotalSubscriptions(filter).then((result) => {
        this.planList.loading = false;

        let results = {};

        result.data.forEach(element => {
          if (results[element.provider_name] === undefined) { results[element.provider_name] = [] }

          results[element.provider_name].push(element)  
        });
        
        this.planList.data = results
        // this.context = result.data._context;
      });
    },
  },
};
</script>

<style lang="scss">
</style>
